<template>
  <div class="h-screen flex flex-col gap-2.5 items-center justify-center">
    <h1 class="text-2xl lg:text-3xl">
      We were not able to process your request at the moment.
    </h1>
    <a
      class="rounded-full py-5 px-8 bg-black text-white"
      href="#"
      @click="() => reloadNuxtApp({ ttl: 5000 })"
      >Reload</a
    >
  </div>
</template>
