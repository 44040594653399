import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArticle, LazyArticleSummary, LazyCheckbox, LazyContentSummary, LazyCuratorWidget, LazyFaq, LazyForm, LazyFullColumnContent, LazyInputHeading, LazyLocationSelect, LazyPage, LazyProductListingSummary, LazyProductPage, LazyProductSelect, LazyProductSummary, LazySelectInput, LazyStandardPageBanner, LazyStockistSummary, LazyTextInput, LazyTextareaInput, LazyThreeColumnContent, LazyTwoColumnContent, LazyUAccordion, LazyUAlert, LazyUAvatar, LazyUAvatarGroup, LazyUBadge, LazyUButton, LazyUButtonGroup, LazyUCarousel, LazyUChip, LazyUDropdown, LazyUIcon, LazyUKbd, LazyULink, LazyUMeter, LazyUMeterGroup, LazyUProgress, LazyUCheckbox, LazyUForm, LazyUFormGroup, LazyUInput, LazyUInputMenu, LazyURadio, LazyURadioGroup, LazyURange, LazyUSelect, LazyUSelectMenu, LazyUTextarea, LazyUToggle, LazyUTable, LazyUCard, LazyUContainer, LazyUDivider, LazyUSkeleton, LazyUBreadcrumb, LazyUCommandPalette, LazyUCommandPaletteGroup, LazyUHorizontalNavigation, LazyUPagination, LazyUTabs, LazyUVerticalNavigation, LazyUContextMenu, LazyUModal, LazyUModals, LazyUNotification, LazyUNotifications, LazyUPopover, LazyUSlideover, LazyUSlideovers, LazyUTooltip, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Article", LazyArticle],
["ArticleSummary", LazyArticleSummary],
["Checkbox", LazyCheckbox],
["ContentSummary", LazyContentSummary],
["CuratorWidget", LazyCuratorWidget],
["Faq", LazyFaq],
["Form", LazyForm],
["FullColumnContent", LazyFullColumnContent],
["InputHeading", LazyInputHeading],
["LocationSelect", LazyLocationSelect],
["Page", LazyPage],
["ProductListingSummary", LazyProductListingSummary],
["ProductPage", LazyProductPage],
["ProductSelect", LazyProductSelect],
["ProductSummary", LazyProductSummary],
["SelectInput", LazySelectInput],
["StandardPageBanner", LazyStandardPageBanner],
["StockistSummary", LazyStockistSummary],
["TextInput", LazyTextInput],
["TextareaInput", LazyTextareaInput],
["ThreeColumnContent", LazyThreeColumnContent],
["TwoColumnContent", LazyTwoColumnContent],
["UAccordion", LazyUAccordion],
["UAlert", LazyUAlert],
["UAvatar", LazyUAvatar],
["UAvatarGroup", LazyUAvatarGroup],
["UBadge", LazyUBadge],
["UButton", LazyUButton],
["UButtonGroup", LazyUButtonGroup],
["UCarousel", LazyUCarousel],
["UChip", LazyUChip],
["UDropdown", LazyUDropdown],
["UIcon", LazyUIcon],
["UKbd", LazyUKbd],
["ULink", LazyULink],
["UMeter", LazyUMeter],
["UMeterGroup", LazyUMeterGroup],
["UProgress", LazyUProgress],
["UCheckbox", LazyUCheckbox],
["UForm", LazyUForm],
["UFormGroup", LazyUFormGroup],
["UInput", LazyUInput],
["UInputMenu", LazyUInputMenu],
["URadio", LazyURadio],
["URadioGroup", LazyURadioGroup],
["URange", LazyURange],
["USelect", LazyUSelect],
["USelectMenu", LazyUSelectMenu],
["UTextarea", LazyUTextarea],
["UToggle", LazyUToggle],
["UTable", LazyUTable],
["UCard", LazyUCard],
["UContainer", LazyUContainer],
["UDivider", LazyUDivider],
["USkeleton", LazyUSkeleton],
["UBreadcrumb", LazyUBreadcrumb],
["UCommandPalette", LazyUCommandPalette],
["UCommandPaletteGroup", LazyUCommandPaletteGroup],
["UHorizontalNavigation", LazyUHorizontalNavigation],
["UPagination", LazyUPagination],
["UTabs", LazyUTabs],
["UVerticalNavigation", LazyUVerticalNavigation],
["UContextMenu", LazyUContextMenu],
["UModal", LazyUModal],
["UModals", LazyUModals],
["UNotification", LazyUNotification],
["UNotifications", LazyUNotifications],
["UPopover", LazyUPopover],
["USlideover", LazyUSlideover],
["USlideovers", LazyUSlideovers],
["UTooltip", LazyUTooltip],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
