import type { StoryBlokVersion } from "@/types";

interface Options {
  resolve_relations?: string | string[];
}

export const usePage = (slug: string, options?: Options) => {
  const config = useRuntimeConfig();

  const version = config.public?.storyblok?.version as StoryBlokVersion;

  return useAsyncStoryblok(slug, {
    version,
    ...(options || {
      resolve_links: "url",
    }),
  });
};

export interface ISbStoriesParams {
  token?: string;
  with_tag?: string;
  is_startpage?: boolean;
  starts_with?: string;
  by_uuids?: string;
  by_uuids_ordered?: string;
  excluding_ids?: string;
  excluding_fields?: string;
  version?: "draft" | "published";
  resolve_links?: "link" | "url" | "story" | "0" | "1" | "link";
  resolve_relations?: string | string[];
  resolve_assets?: number;
  cv?: number;
  sort_by?: string;
  search_term?: string;
  filter_query?: any;
  per_page?: number;
  page?: number;
  from_release?: string;
  language?: string;
  fallback_lang?: string;
  first_published_at_gt?: string;
  first_published_at_lt?: string;
  level?: number;
  published_at_gt?: string;
  published_at_lt?: string;
  by_slugs?: string;
  excluding_slugs?: string;
  _stopResolving?: boolean;
  component?: string;
  filename?: string;
  size?: string;
  datasource?: string;
  dimension?: string;
  content_type?: string;
}

export const useStories = async (params: ISbStoriesParams) => {
  const config = useRuntimeConfig();

  const apiInstance = useStoryblokApi();
  const data = await apiInstance.get("cdn/stories", {
    version: config.public?.storyblok?.version as StoryBlokVersion,
    ...params,
  });

  return data;
};
