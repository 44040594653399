<script setup lang="ts">
const { isProduction } = useEnvironment();

const page = await usePage("config/404-page").catch((err) => {
  const error = useStoryblokError(err);

  throw createError({
    statusCode: 500,
    statusMessage: error.response ?? "Something went wrong",
    fatal: true,
  });
});
</script>

<template>
  <div v-if="page?.content?.component">
    <Seo
      v-if="isProduction && page?.content?.meta"
      :meta="page?.content?.meta"
    />

    <component
      :is="page.content.component"
      :blok="page.content"
      :uuid="page.uuid"
    />
  </div>
</template>
