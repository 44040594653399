<script lang="ts" setup>
import * as Sentry from "@sentry/browser";
const props = defineProps<{ error: any }>();
const route = useRoute();

if (props.error.statusCode !== 404) {
  Sentry.captureMessage(
    `Reported Error: ${props.error.statusMessage ?? props.error.response}`,
  );
}

watch(
  () => route.fullPath,
  () => {
    clearError();
  },
);
</script>

<template>
  <NuxtLayout v-if="props.error.statusCode === 404">
    <ErrorNotFound />
  </NuxtLayout>
  <ErrorFatal v-else />
</template>
