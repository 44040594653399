import isString from "lodash/isString";

export const useStoryblokError = (
  err: any,
): { status: number; response: string } => {
  try {
    let error = err;
    if (isString(error)) {
      error = JSON.parse(err);
    }

    return error;
  } catch (e) {
    return { status: 500, response: "Something went wrong" };
  }
};
