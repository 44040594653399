import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  // const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  return Sentry.init({
    beforeSend: (event, hint) => {
      // check if the originalException is your object and add the data as extras to the Sentry Event
      if (
        typeof hint.originalException === "object" &&
        hint.originalException != null &&
        "key" in hint.originalException
      ) {
        event.extra = {
          ...event.extra,
          ...hint.originalException,
        };
      }
      return event;
    },
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    // Comment out browserTracingIntegration because it interferes with google tag manager script
    integrations: [
      // Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["www.smartstone.com.au", "smartstone.com.au"],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
});
