<script setup>
const nuxtApp = useNuxtApp();

nuxtApp.hook("page:finish", () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

useHead({
  link: [
    {
      rel: "preload",
      href: "/Fonts/6353370/5e580550-c141-447d-8fae-5679715a2dc4.woff2",
      crossorigin: true,
      as: "font",
      type: "font/woff2",
    },
    {
      rel: "preload",
      href: "/Fonts/suisse/SuisseIntl-Light-WebM.woff2",
      crossorigin: true,
      as: "font",
      type: "font/woff2",
    },
    {
      rel: "preload",
      href: "/Fonts/suisse/SuisseIntl-Regular-WebM.woff2",
      crossorigin: true,
      as: "font",
      type: "font/woff2",
    },
    {
      rel: "preload",
      href: "/Fonts/suisse/SuisseIntl-SemiBold-WebM.woff2",
      crossorigin: true,
      as: "font",
      type: "font/woff2",
    },
    {
      rel: "preload",
      href: "/Fonts/6353370/c12e10c5-68d6-46d8-91c7-d4a44bbaf83e.woff",
      crossorigin: true,
      as: "font",
      type: "font/woff2",
    },
  ],
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
